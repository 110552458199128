.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.menu-item.menu-accordion {
  border-radius: 6px;
}

.menu-item.menu-accordion.active {
  background: #50cd89;
}

.app-sidebar-menu-primary.menu
  > .menu-item.here.active
  > .menu-link
  .menu-title,
.app-sidebar-menu-primary.menu > .menu-item.here.active > .menu-link .menu-icon,
.app-sidebar-menu-primary.menu
  > .menu-item.here.active
  > .menu-link
  .menu-icon
  .svg-icon,
.app-sidebar-menu-primary.menu
  > .menu-item.here.active
  > .menu-link
  .menu-icon
  i {
  color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

span.note {
  color: #a1a5b7;
  margin-top: 5px;
  display: inline-block;
}

/* Login Page Desing Css */

.backgroundImg {
  background-image: url(../public/assets/img/loginBackground.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0px;
  height: 100vh;
}

.displaySection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.outerLoginForm {
  background-color: #fff;
  width: 840px;
  border-radius: 12px;
  border: 1px solid #f1f1f2;
  box-shadow: 0px 3px 4px 0px #00000008;
  text-align: center;
}

.logo {
  height: 100px;
  margin-bottom: 30px;
}

.titleText {
  padding: 25px 25px 0px 25px;
}

.titleText > h6 {
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 25px;
  font-weight: bold;
  text-align: left;
}

.hrline {
  color: #e1e3ea;
}

.paddingSection {
  padding: 20px;
}

.inputFieldSection {
  display: flex;
  align-items: center;
  justify-content: Left;
}

.inputTitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #5e6278;
  text-align: left;
}

.inputField {
  background-color: #f1f1f2;
  border-radius: 12px;
  height: 38px;
  width: 100%;
  padding: 12px;
  border: 1px solid #f1f1f2;
}

.passwordPadding {
  padding-top: 17px;
}

.checkBoxItem {
  height: 30px;
  width: 30px;
  background-color: #f1f1f2;
  border: 1px solid #f1f1f2;
  border-radius: 0.5rem;
  margin-right: 10px;
}

.reminderMe {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #a1a5b7;
}

.forgotPassword {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #a1a5b7;
}

.forgotPassword {
  display: flex;
  align-items: center;
}

.reminderPassword {
  padding-top: 25px;
}

.submitButton {
  display: flex;
  align-items: center;
  justify-content: end;
}

.submitButton > button {
  background-color: #2884ef;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #fff;
  border: 1px solid #2884ef;
  border-radius: 10px;
}

.textOr {
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  color: #a1a5b7;
  padding-bottom: 10px;
  width: 32%;
}

.socialLogin {
  text-align: left;
}

/* Discount Coupon Section */

.discountCouponsSction {
  padding: 30px;
}

.discountCouponsBackground {
  background-color: #f8f5f5;
  border-radius: 20px;
  height: 580px;
}

.discountHeader {
  padding: 20px;
}

.disountCoupon {
  display: flex;
  align-items: center;
}

.disountCoupon > h6 {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: #3f4254;
}

.createCouponSection {
  text-align: end;
}

.couponCardSection {
  padding: 20px;
}

.createCoupon {
  color: #fff;
  border-radius: 10px;
  padding: 12px 16px;
  background-color: #3e97ff;
  border: 1px solid#3E97FF;
  font-size: 14px;
  line-height: 18px;
}

.couponHeaderBackground {
  background-color: #3e97ff;
  padding: 20px;
  border-radius: 10px;
}

.couponHeaderBackground.rightbackground {
  background-color: #ffa800;
}

.couponHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.whiteColor {
  color: #fff;
}

.appliesTo {
  font-size: 18px;
  line-height: 24px;
}

.appliesServices {
  font-size: 12px;
  line-height: 18px;
}

.deleteIcon {
  background-color: #fff;
  padding: 7px;
  border-radius: 5px;
}

.couponMiddle {
  text-align: center;
}

.couponCode {
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 10px;
}

.couponCodeText {
  font-size: 38px;
  line-height: 42px;
  padding-bottom: 120px;
}

.positionSection {
  position: relative;
  padding: 0px 40px;
}

.couponBottomBackground {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  position: absolute;
  top: 70%;
  width: 74%;
  left: 13%;
}

.couponDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.couponDetailsText {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: #3f4254;
  padding-bottom: 20px;
}

.bgColorWhite {
  background-color: white;
}

.sendNotificationSection {
  padding: 30px;
}

.sendNotification {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 20px;
}

/* services pages Css */
.serviceTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.customOptions {
  height: 40px;
  width: -webkit-fill-available;
  border-radius: 7px;
  border: 1px solid var(--bs-gray-100);
  padding: 0px 7px;
  background-color: var(--bs-gray-100);
  color: var(--bs-gray-700);
}

.customOptions option {
  background-color: #f0f0f0;
  color: #99a1b7;
  font-size: 14px;
  font-weight: 500;
  padding: 20px 5px;
  border-radius: 10rem;
}

.customOptions:focus-visible {
  outline: none;
}

.modalData {
  /* Your modal styles here */
  display: none;
  position: fixed;
  top: 52%;
  left: 72%;
  transform: translate(-25%, -60%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 9999;
  width: 30%;
}

.authorName {
  font-size: 16px;
  line-height: 20px;
  color: #3f4254;
}

.detailsSection {
  padding-left: 20px;
}

.timeSection {
  display: flex;
  align-items: center;
  justify-content: end;
}

.authorRole {
  font-size: 14px;
  line-height: 14px;
  color: #a1a5b7;
}

.app-navbar-item:hover .modalData {
  display: block;
}

.notificationText {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}

.viewAll {
  cursor: pointer;
}

.notificationList {
  padding-top: 40px;
}

.customAvatar {
  height: 60px;
  width: 60px;
  border-radius: 10px;
}

.leftSection {
  display: flex;
  align-items: center;
  justify-content: left;
}

.error {
  text-align: left;
  color: red;
}

.visibilityHide {
  visibility: hidden;
}

.customCategoryHeader {
  align-items: center;
  justify-content: space-between;
}

.categoryImage {
  height: 60px;
  width: 140px;
  object-fit: cover;
  border-radius: 10px;
}
.serviceImage {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 10px;
}

.read-more-container {
  display: flex;
  gap: 8px; /* Adjust space between buttons */
  margin-top: 4px; /* Adjust margin above buttons */
}

.read-more-container button {
  background-color: transparent; /* No background color */
  border: none; /* No border */
  color: #007bff; /* Primary blue color */
  font-weight: bold;
  cursor: pointer;
  padding: 0; /* Removes padding */
  font-size: 0.75rem; /* Smaller font size */
}

.read-more-container button:hover {
  text-decoration: underline;
  color: #0056b3; /* Darker blue on hover */
}

.colorGreen,
.colorGreen:hover {
  background-color: var(--bs-link-color);
}

.radio-group input,
.radio-group label {
  cursor: pointer;
}

.multiselect-container .search-wrapper {
  border: none;
  padding: 0px;
}

.multiselect-container input {
  margin-top: 0px !important;
}

.td-last {
  display: flex;
  border: none !important;
}

.td-last button {
  margin-left: 5px;
}

/* modal */
/* AlertPopup.css */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px 20px 0px 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content span.close {
  position: absolute;
  right: 15px;
  top: 0px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.primary {
  background-color: #007bff;
  color: white;
}

button.secondary {
  background-color: #6c757d;
  color: white;
}

/* modal end */

.modal.modal-confirmation {
  z-index: 999;
  overflow: hidden;
}

.image-holder {
  object-fit: cover;
}

.custom-multiselect {
  background-color: #f0f0f0 !important;
}

/* chat style  */
.chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50vh; */
  background-color: #f4f4f9;
}

.chat-box {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
}

.chat-body {
  /* flex: 1; */
  padding: 10px;
  /* overflow-y: auto; */
  height: 500px;
  overflow: scroll;
}

.chat-message {
  margin: 10px 0;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
  max-width: 25%;
}

.other {
  background-color: #f1f1f1;
  text-align: left;
  margin-right: auto; /* Align to the left */
}

.self {
  background-color: #007bff;
  color: white;
  text-align: right;
  margin-left: auto; /* Align to the right */
}

.chat-time {
  font-size: 0.8rem;
  color: #666;
  margin-top: 5px;
}
.chat-color {
  color: #fff;
}
.tableheading {
  background-color: rgb(251, 246, 246);
}

.table-container {
  border-radius: 10px; /* Round the corners of the table */
  overflow: hidden; /* Ensures child elements respect the border-radius */
}

.imgclass {
  width: 80px;
  height: 70px;
}
.headingClass {
  padding-left: 10px;
}

.segment-section {
  background-color: #f1f1f4;
  max-width: fit-content;
  padding: 10px 5px;
  border-radius: 10px;
}
.segment-section button {
  margin: 0px 5px;
}

.badge-fixed-width {
  display: inline-block; /* Ensures proper rendering of the width */
  width: 100px; /* Adjust the width as needed */
  text-align: center; /* Centers the text inside the badge */
}
.cursorPointer {
  cursor: pointer;
}
.calanderSection {
  position: absolute;
  right: 0px;
  top: 80px;
  z-index: 9999;
}
.calanderRelative {
  position: relative;
}
.faEyeIconRelative {
  position: relative;
}
.faEyeIcon {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 10px;
  right: 25px;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* keeps it above other content */
}

.loader-box {
  padding: 30px 50px;
  background-color: #fff; /* Background color for the box */
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-style {
  background-color: #e1e3ea;
  border-radius: 10px;
}

.cards {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Noticeable shadow */
  border: 1px solid #e0e0e0; /* Optional light border to enhance contrast */
}

.cards:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3) !important; /* More prominent shadow on hover */
}

.profile-pic-wrapper .symbol {
  background-color: #f0f0f0;
}

.view-chat-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  font-size: 14px;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.view-chat-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}
.popularServicesAlign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.limmitLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.dateTxt {
  font-size: 10px;
}

.button-confirm {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}

.button-confirm:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
  margin-right: 10px;
}

.button-cancel {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}
.withdrawerrortext {
  padding-left: 10px;
}
.custom-multiselect:disabled {
  display: none !important;
}

.nameClass {
  padding-left: 10px;
}

.remove-btn {
  background-color: #ff4d4d; /* Light red background */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}
@media only screen and (max-width: 768px) {
  .outerLoginForm {
    width: 100%;
  }

  .backgroundImg {
    padding: 30px 0px;
  }

  .textOr {
    width: 100%;
  }

  .socialLogin {
    text-align: center;
  }

  .forgotPassword {
    justify-content: end;
  }

  .reminderPassword {
    padding-bottom: 10px;
  }
}
